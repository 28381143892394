import { Document } from "@contentful/rich-text-types"
import { GetStaticPaths, GetStaticProps } from "next"
import Head from "next/head"
import { useRouter } from "next/router"
import { Box, Center, Heading, Text } from "@chakra-ui/layout"
import { ITextPageFields } from "@typings/contentful"
import { Entry, EntryCollection } from "contentful"
import { useTranslations } from "use-intl"
import RichText from "@components/Show/RichText"
import contentfulClient from "@utils/initContentful"

export const getStaticPaths: GetStaticPaths = async () => {
  const pages: EntryCollection<ITextPageFields> =
    await contentfulClient.getEntries({ content_type: "textPage" })

  const paths = pages.items.map((page: Entry<ITextPageFields>) => ({
    params: { slug: page.fields.slug },
  }))

  return {
    paths,
    fallback: true,
  }
}

type TextPage = {
  title: string
  slug: string
  description: string | undefined
  body: Document | null
}

type TextPageProps = {
  page: TextPage
}

export const getStaticProps: GetStaticProps<TextPageProps> = async ({
  params,
}) => {
  const pages: EntryCollection<ITextPageFields> =
    await contentfulClient.getEntries({
      content_type: "textPage",
      "fields.slug[in]": params?.slug,
    })

  if (pages.items.length < 1) {
    return {
      notFound: true,
    }
  }

  const {
    fields: { title, slug, body, description },
  } = pages.items[0]

  return {
    props: {
      page: {
        title,
        slug,
        description,
        body: body || null,
      },
    },
    revalidate: 180,
  }
}

const TextPage = (props: TextPageProps): JSX.Element => {
  const router = useRouter()
  const t = useTranslations("textPage")

  if (router.isFallback) {
    return (
      <Box w="100%" maxW="45rem">
        <Text>{t("loadingMsg")}</Text>
      </Box>
    )
  }

  const {
    page: { title, body, description },
  } = props

  return (
    <>
      <Head>
        <title>{title} – Chefslive</title>
        <meta name="description" content={description} />
        <meta property="og:image" content="/images/default-share-image.png" />
      </Head>
      <Center>
        <Box
          textAlign="left"
          w="100%"
          maxW="45rem"
          mt={[6, null, 12, 16, 20]}
          mb="8"
        >
          <Heading variant="heroHeading" mb={[4, null, 8]}>
            {title}
          </Heading>
          <RichText doc={body} />
        </Box>
      </Center>
    </>
  )
}

export default TextPage
